



















































import { api } from "@/api/api";
import { ApiGetTeachingMaterialCategoryDto, ApiGetTeachingMaterialDto } from "@/api/generated/Api";
import TeachingMaterialModal from "@/components/administration/teachingmaterials/TeachingmaterialModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { VSwitch } from "vuetify/lib";

export default defineComponent({
  name: "TeachingMaterialPage",
  components: { BaseTooltipIconButton, BaseTableFiltered, BaseModal, TeachingMaterialModal },
  setup() {
    const teachingMaterials = ref<ApiGetTeachingMaterialDto[]>();
    const teachingMaterialCategories = ref<ApiGetTeachingMaterialCategoryDto[]>();
    const search = ref<string>("");
    const modalData = ref<ModalBaseData>({
      modalHeadline: "",
      modalType: ModalType.Unspecified,
      showModal: false,
    });

    const loadData = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        teachingMaterials.value = (await api.teachingmaterial.getTeachingMaterialsAsync()).data;
        teachingMaterialCategories.value = (await api.teachingmaterialcategory.getTeachingMaterialCategoryAsync()).data;
      });
    };

    const openNewTeachingmaterial = useOpenModal(ModalType.Add, "læremiddel", modalData);
    const openDisplayTeachingmaterial = useOpenModal(ModalType.Display, "læremiddel", modalData);
    const openEditTeachingmaterial = useOpenModal(ModalType.Edit, "læremiddel", modalData);

    const closeModal = () => {
      modalData.value.showModal = false;
      loadData();
    };

    onMounted(async () => {
      loadData();
    });

    return {
      teachingMaterials,
      search,
      headers,
      modalData,
      openNewTeachingmaterial,
      openDisplayTeachingmaterial,
      openEditTeachingmaterial,
      closeModal,
      teachingMaterialCategories,
      filter,
    };
  },
});

const filter = [
  {
    component: VSwitch,
    value: "isActive",
    staticClass: "mx-3",
    default: true,
    attrs: {
      label: "Vis inaktive",
      inset: true,
    },
    apply: (value: any, model: any) => model || value,
  },
];

const headers = [
  { text: "Kategori", value: "category" },
  { text: "Navn", value: "name" },
  {
    text: "Beskrivelse",
    value: "description",
  },
  { text: "Status", value: "isActive" },
  { text: "Handlinger", value: "actions", sortable: false },
];
